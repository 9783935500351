<template>
  <div id="app">
    <router-view v-if="isShow"></router-view>
  </div>
</template>
<script>
  export default {
    name: 'App',
    provide() {
      return {
        reload: this.reload
      }
    },
    data() {
      return {
        isShow: true
      }
    },
    created() {
      this.refresh()
    },
    methods: {
      // 全局刷新
      reload() {
        this.isShow = false
        this.$nextTick(() => {
          this.isShow = true
        })
      },
      // 防止vuex刷新数据丢失
      refresh() {
        if (sessionStorage.getItem('store')) {
          this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
        }
        window.addEventListener('beforeunload', () => {
          sessionStorage.setItem('store', JSON.stringify(this.$store.state))
        })
      }

    }
  }
</script>
<style lang="less">
  #app {
    font-family: sans-serif, LisztFY-Rg;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #333;
  }

  /deep/ .el-button--primary {
    background: #cda156 !important;
    border: 1px solid #cda156 !important;
  }

  /deep/ .ant-pagination-item {
    font-size: 17px;
  }

  .viewer-canvas {
    >.viewer-transition {
      transition: all .01s !important;
    }
  }
</style>
